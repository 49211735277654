.blog {
    margin-left: 12vw;
    margin-right: 12vw;
    line-height: 160%;
    display: flex;
    flex-direction: column;
    gap: 18px;
    width: unset;
}

.blogContent {

    code {
        background-color: var(--code-background); 
        padding: .1em .3em;
        border-radius: 6px;
    }

    img {
        max-width: 100%;
        max-height: 75vh;
    }

    p:has(img) {
        display: flex;
        justify-content: center;
    }

    h1 {
        margin-top: 12px;
        line-height: 120%;
    }

    h2 {
        margin-top: 12px;
        line-height: 120%;
    }

    h3 {
        margin-top: 12px;
        line-height: 120%;
    }

    h4 {
        margin-top: 12px;
        line-height: 120%;
    }
}


/*For mobile*/
@media screen and (orientation:portrait) {
    .blog {
        margin-left: 16px;
        margin-right: 16px;
        width: unset;
    }
}