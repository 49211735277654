.section {
    padding: 22px;
    border-radius: 10px;
    box-shadow: var(--shadow);
    background-color: var(--panel-background);
    animation: fadeIn 1s ease-out, moveIn 0.5s ease-out;
}

.title {
    margin-top: 0px;
    margin-bottom: 18px;
    line-height: normal;
    overflow-wrap: break-word;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes moveIn {
    from { transform : translateX(25px) }
    to   { transform : translateX(0px) }
}