.container {
    margin-bottom: 35px;
    margin-left: 20px;
    margin-right: 20px;
    text-align: center;
    color: var(--cover-text);
}

.subtext {
    font-size: 13pt;
    margin-top: 5px;
}

#socialBox {
    margin-top: 15px;
}

.socialsItem {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 20pt;
    color: var(--cover-text);
    text-decoration: none;
}

.socialsItem:hover {
    color: var(--cover-text);
    text-decoration: none;
}

.mobileJump {
    display: none;
}

/*
    Mobile // Portrait
*/
@media screen and (orientation:portrait) {
    .mobileJump {
        display: inline;
    }
}